// Shared pane styles.
.pane-style(@bg-color) {
  background-color: @bg-color;
  padding: 20px;

  > .pane-title {
    .fix-colors(@bg: @bg-color, @text: @text-color, @headings: false, @underline: false);
    margin-top: 0;
    padding: 0 10px 15px;

    &:before when (luma(@bg-color) < 50%) and (alpha(@bg-color) > .5) and (luma(@text-color) < 50%) {
      border-bottom: 2px solid white;
    }
  }

  > .pane-content {
    .fix-colors(@bg: @bg-color, @text: @text-color);
    font-size: 15px;
  }
}

// Subdued.
.pane-style-style-subdued {
  .pane-style(@theme-pane-style-subdued-bg-color);
}

// Info.
.pane-style-style-info {
  .pane-style(@theme-pane-style-info-bg-color);
}

// Attention.
.pane-style-style-attention {
  .pane-style(@theme-pane-style-attention-bg-color);
}

// Alternate.
.pane-style-style-alternate {
  .pane-style(@theme-pane-style-alternate-bg-color);
}
